<script lang="ts">
    import { Icon, XMark } from 'svelte-hero-icons';
    import { Button } from '$lib/components/ui/button/index.js';
    import { Check } from 'lucide-svelte';
    import { ChevronDown } from 'lucide-svelte';
    import { tick } from 'svelte';
    import * as Command from '$lib/components/ui/command/index.js';
    import * as Popover from '$lib/components/ui/popover/index.js';
    import { cn } from '$lib/utils.js';

    export let value;
    export let data;
    export let newChatUser;
    export let mobileShowChat;
    export let ChatLoader;

    let comboboxValue: any = '';
    let open: boolean = false;

    interface Contact {
        id: string;
        name: string;
        [key: string]: any;
    }

    $: selectedValue = data.availableContacts.find((f: Contact) => f.name === comboboxValue)?.name ?? 'To...';

    function closeAndFocusTrigger(triggerId: string) {
        open = false;
        tick().then(() => {
            document.getElementById(triggerId)?.focus();
        });
    }

    const findContact = (contacts: Contact[], name: string): Contact => {
        return contacts.find((f) => f.name === name) as Contact;
    };
</script>

<div class="new-conversation-header">
    <div class="details-holder border-b px-3 pb-3 pt-4">
        <div class="flex justify-between gap-[20px]">
            <div class="flex-1">
                <form id="userMessageForm" autocomplete="off" class="w-full">
                    <div class="mfv-errorBox"></div>
                    <Popover.Root bind:open let:ids>
                        <Popover.Trigger asChild let:builder>
                            <Button
                                builders="{[builder]}"
                                variant="outline"
                                role="combobox"
                                aria-expanded="{open}"
                                class="w-full justify-between"
                            >
                                {selectedValue}
                                <ChevronDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content class="w-full max-w-[664px] p-0">
                            <Command.Root>
                                <Command.Input placeholder="Search User..." />
                                <Command.Empty>No user found.</Command.Empty>
                                <Command.Group>
                                    {#each data.availableContacts as availableContact}
                                        <Command.Item
                                            value="{availableContact.name}"
                                            onSelect="{(currentValue) => {
                                                comboboxValue = currentValue;
                                                closeAndFocusTrigger(ids.trigger);
                                                newChatUser = findContact(data.availableContacts, comboboxValue).id;
                                            }}"
                                        >
                                            <Check
                                                class="{cn(
                                                    'mr-2 h-4 w-4',
                                                    comboboxValue !== availableContact.name && 'text-transparent'
                                                )}"
                                            />
                                            {availableContact.name}
                                        </Command.Item>
                                    {/each}
                                </Command.Group>
                            </Command.Root>
                        </Popover.Content>
                    </Popover.Root>
                </form>
            </div>
            <Button
                variant="ghost"
                class="pointer-cursor new-conversation-close p-0 hover:bg-transparent focus:bg-transparent"
                on:click="{() => {
                    value = false;
                    mobileShowChat = false;
                    ChatLoader = true;
                }}"
            >
                <Icon src="{XMark}" size="{'22px'}" solid />
            </Button>
        </div>
    </div>
</div>
